import React from 'react';
import './css/style.css';
import question from './photos/faq.jpg';

function CreateFAQ(){
    return (
        <>
        <div id="faqOverall">
            <div id="faqMainContainer">
                <div id="faqImageContainer">
                    <img src={question}/>
                </div>
                <div id="faqHeader">
                    <h1>FAQ</h1>
                </div>
                <div id="questionsContainer">
                    <div class="questionA">
                        <h2>What is Integrative and Functional Nutrition?</h2>
                        <ul>
                            <li><h3>Personalized nutrition care that focuses on identifying and resolving root causes of illness using therapeutic diets and dietary supplements with an emphasis on optimal gut health </h3></li>
                        </ul>
                        
                    </div>
                    <div class="questionB">
                        <h2>Do you accept insurance?</h2>
                        <ul>
                            <li><h3>We are currently in network for Aetna and United Healthcare only.
                                You can check your price with these plans at this link:<br/><br/><div class="webLink"><a target='blank'href="https://www.faynutrition.com/dietitians/megan-barahona">https://www.faynutrition.com/dietitians/megan-barahona</a><br/><br/></div>
                                If you are a <b><a href="/NewClientPackages">new client</a></b> wishing to use insurance for sessions, please first schedule your free 15-minute phone consult at the homepage link.<br/><br/>
                                If you are an <b>existing or past client</b> and wish to book a follow-up session using insurance, you can use the link above to schedule.<br/><br/>
                                For other insurance plans, we can provide a superbill of services that you can submit to your insurance for possible reimbursement. Check with you insurance plan to determine if you have coverage.</h3></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default function FAQ() {
    return (
        <CreateFAQ />
    )
}